import React from "react";
import { lazy } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainLayout from "../layouts/Main.js";
import DetailLayout from "../layouts/Detail.js";

const PriceEstimationList = lazy(() =>
  import("../pages/PriceEstimation/List.js")
);

const FormPriceEstimation = lazy(() =>
  import("../pages/PriceEstimation/Form.js")
);

const DetailPriceEstimation = lazy(() =>
  import("../pages/PriceEstimation/Detail.js")
);

const MasterDataList = lazy(() =>
  import("../pages/MasterData/List.js")
);

const InvoiceList = lazy(() =>
  import("../pages/Invoice/List.js")
);

const DetailInvoice = lazy(() =>
  import("../pages/Invoice/Detail.js")
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <PriceEstimationList />,
      },
      {
        path: "/master-data",
        element: <MasterDataList />,
      },
      {
        path: "/invoice",
        element: <InvoiceList />,
      },
    ],
  },
  {
    path: "/",
    element: <DetailLayout />,
    children: [
      {
        path: "/price-estimation/:id",
        element: <DetailPriceEstimation />,
      },
      {
        path: "/price-estimation/:id/edit",
        element: <FormPriceEstimation />,
      },
      {
        path: "/price-estimation/create",
        element: <FormPriceEstimation />,
      },
      {
        path: "/invoice/:id",
        element: <DetailInvoice />,
      },
    ],
  },
]);

const RouterApp = () => {
  return <RouterProvider router={router} />;
};

export default RouterApp;
