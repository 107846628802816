import React from 'react'

export default function NotificationIcon({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16671 15.8333C3.9306 15.8333 3.73268 15.7534 3.57296 15.5937C3.41324 15.434 3.33337 15.2361 3.33337 15C3.33337 14.7638 3.41324 14.5659 3.57296 14.4062C3.73268 14.2465 3.9306 14.1666 4.16671 14.1666H5.00004V8.33329C5.00004 7.18052 5.34726 6.15621 6.04171 5.26038C6.73615 4.36454 7.63893 3.77774 8.75004 3.49996V2.91663C8.75004 2.5694 8.87157 2.27426 9.11462 2.03121C9.35768 1.78815 9.65282 1.66663 10 1.66663C10.3473 1.66663 10.6424 1.78815 10.8855 2.03121C11.1285 2.27426 11.25 2.5694 11.25 2.91663V3.49996C12.3612 3.77774 13.2639 4.36454 13.9584 5.26038C14.6528 6.15621 15 7.18052 15 8.33329V14.1666H15.8334C16.0695 14.1666 16.2674 14.2465 16.4271 14.4062C16.5868 14.5659 16.6667 14.7638 16.6667 15C16.6667 15.2361 16.5868 15.434 16.4271 15.5937C16.2674 15.7534 16.0695 15.8333 15.8334 15.8333H4.16671ZM10 18.3333C9.54171 18.3333 9.14935 18.1701 8.82296 17.8437C8.49657 17.5173 8.33337 17.125 8.33337 16.6666H11.6667C11.6667 17.125 11.5035 17.5173 11.1771 17.8437C10.8507 18.1701 10.4584 18.3333 10 18.3333Z"
        fill="currentColor"
      />
    </svg>
  );
}
