import { Button } from 'antd';
import React from 'react'

export default function NavButton({ onClick, props, children }) {
  return (
    <Button
      className="sl-w-8 sl-h-8 sl-bg-[rgba(255,255,255,0.16)] sl-text-white sl-border-0 sl-p-0"
      {...props}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
